import './polyfills.js'
import LocalTime from "local-time"
import ArrowImage from '../../images/arrow.svg'

document.addEventListener('turbolinks:load', function() {
    LocalTime.start();

    try {
        var $carousel = $(".facts-slider").slick({
            arrows: true,
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: 'ondemand',
            prevArrow: `<button class="fact-nav__item"><div class="fact-nav__arrow"><img src=${ArrowImage}></div></button>`,
            nextArrow: `<button class="fact-nav__item"><div class="fact-nav__arrow fact-nav__arrow_right"><img src=${ArrowImage}></div></button>`,
        });


        $(document).on('keydown', function(e) {
            if(e.keyCode == 37) {
                $carousel.slick('slickPrev');
            }
            if(e.keyCode == 39) {
                $carousel.slick('slickNext');
            }
        });

        $(".facts-slider .slick-current.slick-active").focus();
    }
    catch (e) {
        console.log(e);
    }

    function setNum(i = 0) {
        $(".facts-main-item").each(function () {
            i = i + 1;
            $(this).find(".facts-main-item__num").find(".facts-main-item__text").html(i);
        });
    }

    setNum();

    $(".tele__tab").click(function () {
        let $this = $(this);
        $(".tele-date__block").hide();
        $("#day-" + $this.data("day-target")).show();
        $(".tele__tab").removeClass("tele-tab__current");
        $this.addClass("tele-tab__current");
    });

    $(".mobile-menu__button").on("click", function () {
        $(".mobile-menu").toggleClass("show");
        $(".burger").toggleClass("burger_opened");
        $("body").toggleClass("overflow");
    });

    $(".tele__tabs").on("scroll", function () {
        let cur = $(this).scrollLeft();
        if (cur == 0) {
            $('.tele-tabs__wrap').addClass('shadow-right').removeClass('shadow-left');
        } else {
            let max = $(this)[0].scrollWidth - $(this).parent().width();
            if (cur == max) {
                $('.tele-tabs__wrap').addClass('shadow-left').removeClass('shadow-right');
            } else {
                $('.tele-tabs__wrap').addClass('shadow-right shadow-left');
            }
        }
    });

    $(".tele__tabs").trigger("scroll");

});
